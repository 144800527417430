import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import globalMixin from './globalMixin'
import $bus from './eventbus';
import directives from "./directives/";

import axios from 'axios'
import VueAxios from 'vue-axios'

import './css/general.scss'
import './css/styles.scss'

// import '@fortawesome/fontawesome-free/css/all.css'
// import '@fortawesome/fontawesome-free/js/all.js'


window.api_url = 'https://api.wizzo.stream/api_landing/'; 

const app = createApp({
    extends: App
}).use(store).use(router).use(VueAxios, axios)


app.mixin(globalMixin);
directives(app);

// import axios from 'axios'
// import VueAxios from 'vue-axios'
// app.use(VueAxios, axios)

// import VueSweetalert2 from 'vue-sweetalert2';
// import 'sweetalert2/dist/sweetalert2.min.css';
// app.use(VueSweetalert2);


app.config.globalProperties.$bus = $bus;


app.use((app) => {
    let Vue = {
        mixin: (m) => {
            app.mixin(m);
        },
        prototype: {}
    };

    Object.keys(Vue.prototype).forEach((key) => {
        app.config.globalProperties[key] = Vue.prototype[key]
    });
    

  })


app.mount('#app');