<template>
  
  <!-- <router-view/> -->
  <div class="home">
    <nav :class="{ 'top_nav': true, 'active': showNav }">
      <div class="btns">
        <a class="start btn" href="#plans">שנתחיל?</a>
        <a class="login" href="https://dashboard.wizzo.stream/" target="_blank">התחברות</a>
      </div>
      <div class="logo"></div>
    </nav>
    <section ref="head" id="head" class="margin_home head" :class="{ anm_active: enmElements.head.active }">

      <div class="right">
        <div class="btns_logo">
          <div class="btns">
            <a class="start btn" href="#plans">שנתחיל?</a>
            <a class="login" href="https://dashboard.wizzo.stream/" target="_blank">התחברות</a>
          </div>
          <div class="logo"></div>
        </div>
        <div class="text_btn">
          <h1 class="title" v-html="get_word('hero_title')"></h1>
          <p class="text" v-html="get_word('hero_text')"></p>
          <a href="" class="btn">להעלאת סרטון חינם</a>
        </div>
      </div>
      <div class="left">
        <div class="logo"></div>
      </div>
    </section>
    
    <section ref="logos" id="logos" class="logos margin_home" :class="{ anm_active: enmElements.logos.active}">
      <ul>
        <li class="logo_container" v-for="logo in logos" :key="logo.id">
          <a :style="`background-image: url(${logo.pic_url})`" class="logo"></a>
        </li>
      </ul>
    </section>

    <section ref="special" id="special" class="special margin_home" :class="{anm_active: enmElements.special.active}">
      <h2 class="title">מה מיוחד ב-{{ site.display_name }}?</h2>
      <ul class="cards_container centered">
        <li class="card" v-for="(item, i) in specialCards" :key="item.id">
            <div class="icon_container">
              <div class="circle"></div>
              <div class="icon"></div>
            </div>
            <div class="title" v-html="get_word('special_card_' + (i + 1) +'_title')"></div>
            <div class="text" v-html="get_word('special_card_' + (i + 1) +'_text')"></div>
          </li>
        </ul>
    </section>
    
    <section ref="how_it_work" id="how_it_work" class="margin_home" :class="{how_it_work: true, anm_active: enmElements.how_it_work.active}">
      <h2 class="title">איך זה עובד?</h2>
      <ul class="centered">
        <li>
          <span class="num">1</span>
          <h4 class="title" v-html="get_word('how_it_work_1_title')"></h4>
          <p class="text" v-html="get_word('how_it_work_1_text')"></p>
        </li>
        <li>
          <span class="num">2</span>
          <h4 class="title" v-html="get_word('how_it_work_2_title')"></h4>
          <p class="text" v-html="get_word('how_it_work_2_text')"></p>
        </li>
        <li>
          <span class="num">3</span>
          <h4 class="title" v-html="get_word('how_it_work_3_title')"></h4>
          <p class="text" v-html="get_word('how_it_work_3_text')"></p>
        </li>
      </ul>
      <a href="" class="btn"  v-html="get_word('how_it_work_btn')"></a>
    </section>

    <section ref="discover" id="discover" :class="{discover: true, anm_active: enmElements.discover.active}">
      <div class="content centered" :class="{animation: enmElements.discover.active}">
        <h2 class="title" v-html="get_word('discover_title')"></h2>
        <p class="text" v-html="get_word('discover_text')"></p>
        <a href="" class="btn" v-html="get_word('signup_now')"></a>
      </div>
    </section>
    
    <section ref="plans" id="plans" :class="{plans: true, anm_active: enmElements.plans.active}">
      <h2 class="title">המסלולים שלנו!</h2>
      <ul class="plans_container centered">
        <li class="plan" v-for="(plan) in plans" :key="plan.id">
          <a href="" class="plan_name btn" v-text="plan.name"></a>
          <div class="price_container">
            <div class="price_shekel">
              <p class="price" v-text="plan.price"></p>
              <span class="shekel">₪</span>
            </div>
            <p class="per" v-text="plan.text_ander_praice"></p>
          </div>
          <router-link :to="{ name: 'plan', params: { id: plan.id}}" class="sign_in btn" v-html="get_word('signup_now')"></router-link>
          <ul class="advantages_container">
            <li class="advantage">
              <span class="check"></span>
              <p class="text" v-text="plan.text_field_a"></p>
            </li>
            <li class="advantage">
              <span class="check"></span>
              <p class="text" v-text="plan.text_field_b"></p>
            </li>
            <li class="advantage">
              <span class="check"></span>
              <p class="text" v-text="plan.text_field_c"></p>
            </li>
            <li class="advantage">
              <span class="check"></span>
              <p class="text" v-text="plan.text_field_d"></p>
            </li>
          </ul>
        </li>
      </ul>
    </section>
    
    <section ref="lead_form" id="lead_form" :class="{lead_form: true, anm_active: enmElements.lead_form.active}">
      <div class="content_form centered">
        <div class="content">
          <h3 class="title"  v-html="get_word('contact_title')"></h3>
          <p class="text" v-html="get_word('contact_text')"></p>
        </div>
        <form @submit.prevent="addLead">
          <input type="text" name="" id="" v-model="leadForm.name" placeholder="שם מלא">
          <input type="email" name="" id="" v-model="leadForm.email" placeholder="מייל">
          <textarea name="" id="" rows="10" v-model="leadForm.content" placeholder="הערות"></textarea>
          <input class="btn" type="submit" :value="get_word('contact_send_btn')">
        </form>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ['site', 'plans'],
  data() {
    return {
      // site: {
      //   name: 'wizzo-stream',
      //   color1: '#2EF7BA',
      //   color2: '#2B2D42',
      // },
      
      leadForm: {
        name: '',
        email: '',
        content: ''
      },
      logos: [
        {id: 1, pic_url: 'https://www.hidabroot.org/themes/HDB/images/logo.svg'},
        {id: 2, pic_url: 'https://www.nadlancenter.co.il/themes/MGZ/images/245X82.jpg'},
        {id: 3, pic_url: 'https://www.hidabroot.org/themes/HDB/images/logo.svg'},
        {id: 4, pic_url: 'https://www.nadlancenter.co.il/themes/MGZ/images/245X82.jpg'},
        {id: 5, pic_url: 'https://www.hidabroot.org/themes/HDB/images/logo.svg'},
        {id: 6, pic_url: 'https://www.nadlancenter.co.il/themes/MGZ/images/245X82.jpg'},
      ],
      specialCards: [
        {id: 1, title: 'קידוד ואופטימיזציה מהירים במיוחד.', text: 'פיתחנו טכנולוגיה מתקדמת שמכינה את הוידאו שלכם להזרמה תוך חלקיקי שניות ומעניקה חווית גלישה מהירה וחדשנית.'},
        {id: 2, title: 'השמעה אדפטיבית בכל סוג מסך.', text: 'במחשב, בטאבלט, במכשירים ניידים, עם קליטה חזקה או חלשה - אנו מתאימים את איכות הווידאו הטובה ביותר לסיטואציה מבלי לפגוע בחווית הצפייה.'},
        {id: 3, title: 'מותאם לאתרי חדשות וסטרימינג.', text: 'אנחנו מבינים את הצרכים הייחודיים של פלטפורמות חדשות וסטרימינג, ומעניקים שירות VIP ומהירות צפייה יוצאת דופן גם לאתרים גדולים וגלובליים.'},
        {id: 4, title: 'חווית וידאו ללא מיתוג.', text: 'רק אצלנו, תהנו מחווית גלישה ממוקדת ונקייה מהסחות דעת, ללא סימני מים או לוגו של ' + 'wizzo stream' +' על מסך הצפייה.'},
        {id: 5, title: 'איכויות וידאו מגוונות.', text: 'טכנולוגיית הקידוד המתקדמת שלנו מבטיחה שהתוכן שלכם ייראה מדהים במגוון רחב של איכויות, מהסטנדרט הנמוך ביותר ועד ל4K משגע.'},
      ],
      showNav: false,
      showWow: true,
      enmElements: {
        head: {active: null, dis: {}, before: 100},
        logos: {active: null, dis: {}, before: 100},
        special: {active: null, dis: {}, before: 250},
        how_it_work: {active: null, dis: {}, before: this.is_mobile() ? 250 : 350},
        discover: {active: null, dis: {}, before: this.is_mobile() ? 0 : 300},
        plans: {active: null, dis: {}, before: 100},
        lead_form: {active: null, dis: {}, before: 100},
      },
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    for (let key in this.enmElements) {
      this.enmElements[key].dis.start = document.getElementById(key).offsetTop + window.top.scrollY;
      this.enmElements[key].dis.end = (document.getElementById(key).offsetTop + window.top.scrollY) + (document.getElementById(key).offsetHeight);
    }
    console.log(this.enmElements);
    this.handleScroll('init')
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    
    handleScroll(type=null) {
      if (type === 'init') {
        if(window.top.scrollY > 0) {
          for (let key in this.enmElements) {
            this.enmElements[key].active = true
          }
        } 
        return;
      }
      if (this.is_mobile()) {
        if (window.top.scrollY > 50) {
          this.showNav = true
        } else {
          this.showNav = false
        }
      } else {
        if (window.top.scrollY > 150) {
          this.showNav = true
        } else {
          this.showNav = false
        }
      }
      for (let key in this.enmElements) {
        if (((this.enmElements[key].dis.start + this.enmElements[key].before) <= (window.top.scrollY + window.innerHeight)) && (this.enmElements[key].dis.end > (window.top.scrollY + window.innerHeight))) {console.log(key);
          if (this.enmElements[key].active === null) {this.enmElements[key].active = true;}
        }
      }

    },
    addLead() {
      this.api({action: 'contact/add', data: this.leadForm}, (data) => {
        if (data.data) {
          this.leadForm = {
            name: '',
            email: '',
            content: ''
          },
          alert('נשלח בהצלחה')
        }
      });
    }
  },
}
</script>

<style lang="scss">
.home {display: flex; flex-direction: column; padding-top: var(--gap); background-color: #fff;
  
  section { margin-bottom: 60px;}
  .head {display: flex; justify-content: space-between; gap: var(--gap);
    .right { border-radius: var(--radius_b); background: var(--bg1); padding-top: 44px; padding-right: 76px; padding-bottom: 88px; display: flex; flex-direction: column; justify-content: space-between; gap: 6.6vw;
      @media (min-width: 600px) { flex-basis: 50%;}
      @media (max-width: 600px) { flex-basis: 100%; padding: var(--gap);}
      .btns_logo { display: flex; align-items: center; justify-content: space-between; 
        .btns { display: flex; align-items: center;gap: var(--gap);
          .start {  }
          .login { color: #000; font-weight: 400; font-size: var(--h4); padding: 6px; }
        }
        .logo {
          @media (min-width: 600px) {display: none;}
          @media (max-width: 600px) {}
        }
      }
      .text_btn { display: flex; flex-direction: column; gap: var(--gap_big); width: 78%;
        @media (min-width: 600px) {}
        @media (max-width: 600px) {width: 100%;}
        .title {font-size: var(--h1);  font-weight: 700;}
        .text { font-size: var(--h3); font-weight: 400;}
        // .btn {padding: var(--padding_small) var(--padding); border-radius: var(--radius_xs); background: var(--color1);  font-size: var(--h4); width: fit-content;} 
      }
    }
    .left {flex-basis: 50%; position: relative; background-image: url(../assets/bg_head.jpg); background-position: center center; background-size: cover; border-radius: var(--radius_b);
      @media (min-width: 600px) {}
      @media (max-width: 600px) {display: none;}
      .logo { position: absolute; top: var(--gap_big); left: var(--gap_big);  background-image: url(../assets/logo.png); background-position: center center; background-size: contain; background-repeat: no-repeat; width: 140px; height: 60px;}
    }
  }
  section.logos { display: none;
    @media (min-width: 600px) {}
    @media (max-width: 600px) {display: none;}
    ul { display: flex; justify-content: space-between;
      li.logo_container { 
        .logo { display: inline-flex; background-position: center center; background-size: contain; background-repeat: no-repeat; width: 230px;  height: 50px; }
      }
    }
  }
  section.special { position: relative; overflow: hidden; display: flex; flex-direction: column; gap: 60px; background: var(--bg1); border-radius: var(--radius_b);
    @media (min-width: 600px) { padding-block: 90px 75px; padding-inline: 77px;}
    @media (max-width: 600px) { padding: var(--padding_b); }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   inset: 0;
    //   background: rgba(150, 150, 150, 0.5);
    //   transition: all 0.3s ease;
    // }
    &.anm_active {
      // &::after {
      //   content: '';
      //   position: absolute;
      //   inset: 0;
      //   background: rgba(0, 0, 0, 0);
      // }
      ul.cards_container {
        li.card {
          @media (min-width: 600px) {
            &:nth-child(1) { transform: translate(0%);}
            &:nth-child(2) { transform: translateY(0%);}
            &:nth-child(3) { transform: translate(-0%);}
            &:nth-child(4) { transform: translate(-0%);}
            &:nth-child(5) { transform: translate(-0%);}
          }
          @media (max-width: 600px) {
            &:nth-child(1) { opacity: 1;}
            &:nth-child(2) { opacity: 1;}
            &:nth-child(3) { opacity: 1;}
            &:nth-child(4) { opacity: 1;}
            &:nth-child(5) { opacity: 1;}
          }
        }
      }
    }
    >.title { font-size: var(--h1); font-weight: 700; text-align: center;}
    ul.cards_container {position: relative; display: flex; justify-content: center; gap: var(--gap);
      @media (min-width: 600px) { flex-wrap: wrap;}
      @media (max-width: 600px) { flex-direction: column;}
      
      li.card { transition: all 0.3s ease; background: #fff; padding: var(--gap); flex-basis: calc(33.3% - ((var(--gap) * 2) / 3)); display: flex; flex-direction: column; gap: var(--gap_small); border-radius: var(--radius_s);
        @media (min-width: 600px) {
          &:nth-child(1) { transform: translate(25%,-50%);}
          &:nth-child(2) { transform: translateY(-50%);}
          &:nth-child(3) { transform: translate(-25%, -50%);}
          &:nth-child(4) { transform: translate(25%, 50%);}
          &:nth-child(5) { transform: translate(-25%, 50%);}
        }
        @media (max-width: 600px) {
          &:nth-child(1) { opacity: 0.2;}
          &:nth-child(2) { opacity: 0.2;}
          &:nth-child(3) { opacity: 0.2;}
          &:nth-child(4) { opacity: 0.2;}
          &:nth-child(5) { opacity: 0.2;}
        }
        .icon_container {position: relative; width: fit-content;
          .circle {width: 30px; height: 30px; border-radius: 100%; background: var(--color1);}
          .icon { position: absolute; top: 50%; right: 50%; width: 24px; height: 24px; background-image: url(../assets/srtim.svg); background-position: center center; background-size: contain; background-repeat: no-repeat; }
        }
        .title {font-size: var(--h3);  font-weight: 700;}
        .text {font-size: var(--h4);  font-weight: 400;}
      }
    }
  }
  section.how_it_work { overflow: hidden; position: relative; display: flex; flex-direction: column; align-items: center; padding-bottom: 50px; min-height: 290px;
    @media (min-width: 600px) { padding-inline: 100px;}
    @media (max-width: 600px) { padding: var(--padding_b); }
    &.anm_active {
      li {
        @media (min-width: 600px) {
          &:nth-child(2) { margin-top: 33px;}
          &:nth-child(3) { margin-top: 84px;}
        }
        @media (max-width: 600px) {
          &:nth-child(1) { transform: translateX(0%);}
          &:nth-child(2) { transform: translateX(0%);}
          &:nth-child(3) { transform: translateX(0%);}
        }
      }
      .btn {right: 50%;}
    }
    h2.title { font-size: var(--h1);  text-align: center; margin-bottom: 35px; font-weight: 700; }
    ul { display: flex;
      @media (min-width: 600px) { justify-content: space-between; margin-bottom: 66px; gap: var(--gap);}
      @media (max-width: 600px) {flex-direction: column; gap: var(--gap_big);}
      li { overflow: hidden; transition: all 0.3s ease; z-index: 1; position: relative; padding: var(--gap); display: flex; flex-direction: column; gap: 5px; justify-content: end; height: 203px; border-radius: var(--radius_s);border-left: 4px solid var(--color1); background: var(--bg1);
        @media (min-width: 600px) { flex-basis: calc(33.3% - ((var(--gap) * 2) / 3));
          &:nth-child(3) { margin-top: 0px;}
          &:nth-child(1) { margin-top: 0px;}
          &:nth-child(2) { margin-top: 0px;}
        }
        @media (max-width: 600px) {
          &:nth-child(1) { transform: translateX(100%);}
          &:nth-child(2) { transform: translateX(-100%);}
          &:nth-child(3) { transform: translateX(100%);}
        }
        .num {z-index: 2;position: absolute; top: 0; bottom: 0; right: 5px; color: #fff; width: fit-content; font-size: 340px; transform: translateY(-64px); font-weight: 600;}
        .title {font-size: var(--h3); font-weight: 700;  z-index: 3; }
        .text { z-index: 3;}
      }
    }
    .btn {  transition: all 0.3s ease; position: absolute; top: 100%;
      @media (min-width: 600px) {right: -10%; transform: translate(50%, -100%);}
      @media (max-width: 600px) {right: 50%; transform: translateX(50%);}
    }
  }
  section.discover {overflow: hidden; background-image: url(../assets/discover_bg.jpg);background-size: cover; background-repeat: no-repeat; background-position: center center; border-radius: var(--radius_b);display: flex; flex-direction: column; align-items: center; justify-content: center; gap: var(--gap); position: relative;
    @media (min-width: 600px) { height: 705px;}
    @media (max-width: 600px) { height: 505px;}
    .content {position: absolute; top: -0%; transform: translateY(-50%); display: flex; flex-direction: column; align-items: center; justify-content: center; gap: var(--gap); 
      @media (min-width: 600px) {}
      @media (max-width: 600px) {padding-inline: var(--padding_b);}
      h2.title {color: #fff; font-size: var(--h1); font-weight: 700;
        @media (min-width: 600px) {}
        @media (max-width: 600px) {text-align: center;}
      }
      .text {color: #fff; font-size: var(--h3); text-align: center;
        @media (min-width: 600px) { width: 32%;}
        @media (max-width: 600px) {}
      }
    }
  }
  section.plans { display: flex; flex-direction: column;
    @media (min-width: 600px) { gap: 100px; padding-inline: 80px;}
    @media (max-width: 600px) { padding: var(--padding_b); gap: 50px;}
    &.anm_active {
      ul.plans_container {
        li.plan {
          @media (min-width: 600px) {
            &:nth-child(1) { animation: card1 1s 0.4s forwards; }
            &:nth-child(2) { animation: card2 1s 0.2s forwards; }
            &:nth-child(3) { animation: card3 1s 0s forwards; }
          }
          @media (max-width: 600px) {}
        }
      }
    }
    h2.title { font-weight: 700; font-size: var(--h1); text-align: center;}
    ul.plans_container { display: flex;
      @media (min-width: 600px) { justify-content: space-between;}
      @media (max-width: 600px) { flex-direction: column; gap: 50px;}
      li.plan { position: relative; flex-basis: calc(33.3% - ((var(--gap) * 2) / 3)); border-radius: var(--radius_b); display: flex; flex-direction: column; background: var(--bg2); padding-inline: var(--gap_big); border-bottom: 18px solid var(--color1);
        @media (min-width: 600px) { min-height: 741px;  padding-top: 100px;
          &:nth-child(1) { transform: translateX(200%); }
          &:nth-child(2) { transform: translateX(300%); }
          &:nth-child(3) { transform: translateX(400%); }
        }
        @media (max-width: 600px) { padding-top: 50px;  padding-bottom: 40px;}
        .plan_name {position: absolute; top: 0; right: 50px; transform: translateY(-50%);}
        .price_container {display: flex; flex-direction: column; margin-bottom: var(--gap_big);align-items: center;
          @media (min-width: 600px) { gap: var(--gap_small); }
          @media (max-width: 600px) {}
          .price_shekel {display: flex; align-items: end; gap: 5px; height: fit-content;
            .price { font-size: 110px; font-weight: 700;}
            .shekel { font-size: 50px; margin-bottom: 21px; }
          }
          .per {font-size: var(--h3); text-align: center;}
        }
        .sign_in {width: 100%;padding: 24px 32px;  margin-bottom: 48px; font-size: var(--h3);
          @media (min-width: 600px) {}
          @media (max-width: 600px) {padding-block: var(--padding_n);}
        }
        
      }
    }
  }
  section.lead_form { margin-bottom: 0; padding-inline: 20px;  color: #fff; background: linear-gradient(0deg, rgba(43, 45, 66, 0.75) 0%, rgba(43, 45, 66, 0.75) 100%), url(../assets/lead_form_bg.jpg), lightgray 50% / cover no-repeat; border-radius: 32px 32px 0px 0px; 
    .content_form { display: flex; align-items: self-start; justify-content: space-between; justify-content: space-between;
      @media (min-width: 600px) { padding-block: 100px;}
      @media (max-width: 600px) {flex-direction: column; padding-block: 50px;}
      .content { display: flex; flex-direction: column; justify-content: space-between; gap: var(--gap); margin-bottom: var(--gap_big);
        @media (min-width: 600px) { flex-basis: 35%;}
        @media (max-width: 600px) {}
        h3.title {font-size: var(--h1); font-weight: 700;}
        .text {font-size: var(--h2);}
      }
      form { display: flex; flex-wrap: wrap;justify-content: flex-end;
        @media (min-width: 600px) { flex-basis: 60%; gap: var(--gap_big);}
        @media (max-width: 600px) { gap: var(--gap);}
        input, textarea { border-radius: var(--radius_xs); outline: none; border: none;
          @media (min-width: 600px) { padding: 18px; font-size: var(--h4);}
          @media (max-width: 600px) { padding: 18px; font-size: var(--h4);}
        }
        input:not([type=checkbox]) {
          @media (min-width: 600px) { flex-basis: calc(50% - (var(--gap_big) / 2)); height: 70px;}
          @media (max-width: 600px) { width: 100%; flex-basis: calc(50% - (var(--gap) / 2)); height: 50px;}
        }
        textarea {flex-basis: 100%;
          @media (min-width: 600px) { height: 234px;}
          @media (max-width: 600px) { height: 180px;}
        }
        input[type=submit] {align-self: flex-end; padding-inline: var(--gap_big); border: none; outline: none; font-size: var(--h3); cursor: pointer;
          @media (min-width: 600px) { height: 70px;}
          @media (max-width: 600px) {width: 100%; height: 60px;}
        }
      }
    }
  }
}
</style>
