<template>
  <div id="plan_view" v-if="plan">
    <div class="centered">
        <div class="details_signup_container">
            <div class="details_container">
                <div class="title_price_container">
                    <h2 class="title" v-text="'תוכנית ' + plan.name"></h2>
                    <div class="price_con">
                        <div class="price_shekel">
                            <p class="price" v-text="plan.price"></p>
                            <span class="shekel">₪</span>
                        </div>
                        <div class="per" v-text="plan.text_ander_praice"></div>
                    </div>
                </div>
                <div class="includes">
                    <h4>כלול בתוכנית</h4>
                    <ul class="advantages_container">
                        <li class="advantage">
                            <span class="check"></span>
                            <p class="text" v-text="plan.text_field_a"></p>
                        </li>
                        <li class="advantage">
                            <span class="check"></span>
                            <p class="text" v-text="plan.text_field_b"></p>
                        </li>
                        <li class="advantage">
                            <span class="check"></span>
                            <p class="text" v-text="plan.text_field_c"></p>
                        </li>
                        <li class="advantage">
                            <span class="check"></span>
                            <p class="text" v-text="plan.text_field_d"></p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="signup_container">
                <template v-if="!formSent">
                    <h3 v-html="get_word('plan_form_title')"></h3>
                    <form @submit.prevent="handleSignup">
                        <ul>
                            <li>
                                <input type="text" name="name" placeholder="שם" v-model="signupForm.name">
                            </li>
                            <li>
                                <input type="email" name="email" placeholder="אמייל" v-model="signupForm.email">
                            </li>
                            <li>
                                <input type="tel" name="phone" placeholder="טלפון" v-model="signupForm.phone">
                            </li>
                            <li>
                                <textarea name="note" v-model="signupForm.content" placeholder="הערות"></textarea>
                            </li>
                            <!-- <li>
                                <input type="password" name="password" placeholder="סיסמא" v-model="password">
                            </li> -->
                            <li>
                                <input type="submit" class="btn" :value="get_word('plan_form_send_btn')">
                            </li>
                        </ul>
                    </form>
                </template>
                <template v-else>
                    <p class="thanks">פרטייך נשלחו בהצלחה, ניצור קשר בהקדם</p>
                </template>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['site'],
    data() {
        return {
            plan: null,
            formSent: false,
            signupForm: {
                name: '', 
                email: '', 
                phone: '', 
                content: '',
                plan_id: this.$route.params.id
                // password: '', 
            }
        }
    },
    mounted() {
        this.api({ action: 'plans/get', data: { id: this.$route.params.id}}, (data)=>{
            this.plan = data.data;
        })
    },
    methods: {
        handleSignup() {
            this.api({action: 'contact/add', data: this.signupForm}, (data) => {
                if (data.data) {
                    this.signupForm = {
                        name: '', 
                        email: '', 
                        phone: '', 
                        content: '', 
                    },
                    this.formSent = true
                    // alert('נשלח בהצלחה')
                }
            });
        }
    }
}
</script>

<style lang="scss">
#plan_view { background: #F6F7F8;
    .details_signup_container { min-height: 100vh; display: flex; gap: 40px;
        @media (min-width: 600px) { padding: 60px;}
        @media (max-width: 600px) {  flex-direction: column;  padding: 20px;}
        .details_container { flex: 4; 
            .title_price_container { display: flex; flex-direction: column; gap: 26px; padding-block: 20px; border-bottom: 1px #D4DADF solid;
                h2.title { font-size: var(--h1); }
                .price_con { display: flex; gap: 12px; align-items: baseline;
                    @media (min-width: 600px) {}
                    @media (max-width: 600px) { flex-direction: column;     align-items: self-start;}
                    .price_shekel {display: flex; align-items: end; gap: 5px; height: fit-content;
                        .price { font-size: 110px; font-weight: 700;}
                        .shekel { font-size: 50px; margin-bottom: 21px; }
                    }
                    .per { font-size: var(--h3);}
                }
            }
            .includes {  border-bottom: 1px #D4DADF solid; padding-block: 20px; display: flex; flex-direction: column; gap: 26px;
                h4 { font-size: var(--h3);}
            }
        }
        .signup_container { flex: 5; display: flex; flex-direction: column; gap: var(--gap_big); box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); background: #fff; border-radius: 16px;
            @media (min-width: 600px) { padding: 60px;}
            @media (max-width: 600px) { padding: 30px;}
            h3 { font-size: var(--h2); margin: 0 auto;}
            ul { display: flex; flex-direction: column; gap: var(--gap);
                li { 
                    input { height: 60px;}
                }
            }
            .thanks {     font-size: var(--h2); margin-top: 116px; margin-inline: auto; text-align: center;}

        }
    }
}
</style>